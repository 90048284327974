import { api } from "../api/stagingApi.js";

export async function createProject(idcompany, { name, order }) {
  try {
    const response = await api.post(`/company/${idcompany}/project`, {
      name,
      order,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getProjectsByCompanyId(idcompany, { archived }) {
  try {
    const response = await api.get(`/company/${idcompany}/project`, {
      params: {
        archived,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getProject(idproject) {
  try {
    const response = await api.get(`/company/project/${idproject}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function updateProject(idproject, { name, order, archived }) {
  try {
    const response = await api.patch(`/company/project/${idproject}`, {
      name,
      order,
      archived,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function archiveCompany(idproject) {
  try {
    const response = await api.delete(`/company/project/${idproject}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function deleteProjectById(idProject) {
  try {
    const response = await api.delete(`/dashboard/project/${idProject}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}