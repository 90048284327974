import { api } from "../api/stagingApi.js";

export async function createCompany({ name, order }) {
  try {
    const response = await api.post(`/company`, {
      name,
      order,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getCompaniesByGroup({ archived }) {
  try {
    const response = await api.get(`/company`, {
      params: {
        archived,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function getCompany(idcompany) {
  try {
    const response = await api.get(`/company/${idcompany}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function updateCompany(idcompany, { name, order, archived }) {
  try {
    const response = await api.patch(`/company/${idcompany}`, {
      name,
      order,
      archived,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function archiveCompany(idcompany) {
  try {
    const response = await api.delete(`/company/${idcompany}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}

export async function deleteCompanyId(idcompany) {
  try {
    const response = await api.delete(`/dashboard/company/${idcompany}`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message) {
      return {
        error: true,
        message: error?.response?.data?.message,
        details: error?.response?.data?.details || "",
      };
    } else {
      return {
        error: true,
        message: "An error has occurred",
        details: "",
      };
    }
  }
}
